* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  width: 480px;
  margin: 50px auto;
}

.search-box, .console {
  margin: 10px auto;
}

.input-line-box {
  margin-bottom: 10px;
}

.title {
  float: left;
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.button {
  display: block;
  width: 6em;
  margin: 0 auto
}

.console {
  padding-top: 10px;
}

.row-title {
  text-align: right;
  padding-right: 5px;
}

tr:nth-child(odd) {
  background-color: #efefef;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
